import { Capacitor } from '@capacitor/core';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { NBXStorage } from '@nbx/frontend-helpers/storage';

const RUM_CONFIG = {
  base: {
    env: process.env.NODE_ENV,
    site: 'datadoghq.eu',
    sessionSampleRate: 5,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: []
  },
  mobile: {
    applicationId: 'fefc0b9c-167c-47e2-ac94-38d49a5dfa32',
    clientToken: 'pubd982a5aa7d97884078cc0e3417917ae7',
    service: 'mobile-app'
  },
  web: {
    applicationId: '74fc4786-c9e6-406d-a18f-8316dbfc258e',
    clientToken: 'pub30a98fa3adf475994f6361d25627a238',
    service: 'web-app'
  }
};

const LOGS_CONFIG = {
  forwardErrorsToLogs: true,
  forwardConsoleLogs: ['error', 'warn', 'info', 'log'],
  sampleRate: 100,
  beforeSend: log => {
    log.view.url = log.view.url.replace(/verifyLoginEmail=[^&]*/, 'verifyLoginEmail=REDACTED');
    log.view.url = log.view.url.replace(/verifyLoginIdToken=[^&]*/, 'verifyLoginIdToken=REDACTED');
  }
};

const setup = async config => {
  if (process.env.NODE_ENV === 'development') {
    return;
  }

  datadogRum.init(config);
  datadogLogs.init({ ...config, ...LOGS_CONFIG });

  const userJSON = await NBXStorage.getItem('user');
  const user = userJSON ? JSON.parse(userJSON) : null;
  if (user?.id) {
    datadogRum.setUser({ id: user?.id });
  }
};

export const setupDatadogWebApp = () =>
  setup({
    ...RUM_CONFIG.base,
    ...RUM_CONFIG.web,
    // eslint-disable-next-line no-undef
    version: __SHORT_COMMIT_HASH__
  });

export const setupDatadogMobileApp = () => {
  const platform = Capacitor.getPlatform();
  setup({
    ...RUM_CONFIG.base,
    ...RUM_CONFIG.mobile,
    // eslint-disable-next-line no-undef
    version: `${platform}.${__SHORT_COMMIT_HASH__}`
  });
};
