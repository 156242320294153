/* eslint-disable no-console */
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { NbxCapacitorFirebaseAuth } from '@nbx/capacitor-firebase-auth';

import { Capacitor } from '@capacitor/core';

import { NBXStorage } from '@nbx/frontend-helpers/storage';
import isMobileDevice from '../helpers/isMobileDevice';
import { createLogger } from '../helpers/Logger';
import importFirebase from './firebase-async';
import NBXUsers from './users.service';

let firebaseConfig = {};
let appInstance = null;
let importedFirebaseAuth = null;
let vippsOpenIDConnectProvider = null;
let googleOpenIDConnectProvider = null;
let appleOpenIDConnectProvider = null;

const log = createLogger('[firebase]');
const isAndroidBrowser = /(android)/i.test(navigator.userAgent);
const VIPPS_SCOPES = 'openid api_version_2 address birthDate name phoneNumber';

const Firebase = {
  setConfig: config => {
    if (isAndroidBrowser) {
      config.authDomain = config.authDomain.replace('app', 'auth');
    }
    return (firebaseConfig = config);
  },

  // https://firebase.google.com/docs/reference/js/app.md#initializeapp
  initializeApp: async config => {
    if (importedFirebaseAuth) return importedFirebaseAuth;

    const [firebaseApp, firebaseAuth] = await importFirebase();
    appInstance = firebaseApp.initializeApp(config);
    // See https://github.com/firebase/firebase-js-sdk/issues/5019
    if (window.PLATFORM !== 'web') {
      firebaseAuth.initializeAuth(appInstance, {
        persistence: firebaseAuth.indexedDBLocalPersistence
      });
    }

    vippsOpenIDConnectProvider = new firebaseAuth.OAuthProvider('oidc.vipps');
    vippsOpenIDConnectProvider.addScope(VIPPS_SCOPES);

    googleOpenIDConnectProvider = new firebaseAuth.OAuthProvider('google.com');

    appleOpenIDConnectProvider = new firebaseAuth.OAuthProvider('apple.com');

    importedFirebaseAuth = firebaseAuth;
    return importedFirebaseAuth;
  },

  importAuth: async () => {
    return Firebase.initializeApp(firebaseConfig);
  },

  // https://firebase.google.com/docs/reference/js/auth.md#getauth
  auth: () => Firebase.importAuth().then(firebaseAuth => firebaseAuth.getAuth(appInstance)),

  // https://firebase.google.com/docs/reference/js/auth.auth.md#auth_interface
  user: async () => {
    if (Capacitor.getPlatform() === 'ios') {
      const nativeUser = await FirebaseAuthentication.getCurrentUser();
      if (nativeUser?.user) {
        log.info('Using native user()');
        return nativeUser.user;
      }
    }
    // we sign in user in web sdk, native layer is used only for obtaning a token from providers
    // except ios, we cannot show sms mfs captcha using web because of restrictions apple/capacitor http/https schemes on ios
    // we cannot use apple credential twice because of nonce
    // so, we can only signing user on native to properly handle sms mfa on ios with native captcha
    return Firebase.auth().then(auth => auth.currentUser);
  },

  // https://firebase.google.com/docs/reference/js/auth.md#getidtoken
  getIdTokenFromNative: async forceRefresh => {
    if (Capacitor.getPlatform() === 'ios') {
      const nativeUser = await FirebaseAuthentication.getCurrentUser();
      if (nativeUser?.user) {
        const token = await FirebaseAuthentication.getIdToken({ forceRefresh }).then(res => res.token);
        return token;
      }
    }

    return Firebase.importAuth().then(async firebaseAuth => {
      const user = await Firebase.user();
      return firebaseAuth.getIdToken(user, forceRefresh);
    });
  },

  getIdToken: async forceRefresh => {
    return Firebase.importAuth().then(async firebaseAuth => {
      const user = await Firebase.user();
      return firebaseAuth.getIdToken(user, forceRefresh);
    });
  },

  // https://firebase.google.com/docs/reference/js/auth.md#createuserwithemailandpassword
  signUp: (email, password) =>
    Firebase.importAuth().then(async firebaseAuth => {
      const auth = await firebaseAuth.getAuth();
      return firebaseAuth.createUserWithEmailAndPassword(auth, email, password);
    }),

  // https://firebase.google.com/docs/reference/js/auth.md#getadditionaluserinfo
  getAdditionalUserInfo: async userCredential => {
    if (Capacitor.getPlatform() === 'ios') {
      const nativeUser = await FirebaseAuthentication.getCurrentUser();
      if (nativeUser?.user) {
        return FirebaseAuthentication.getAdditionalUserInfo();
      }
    }
    return Firebase.importAuth().then(firebaseAuth => firebaseAuth.getAdditionalUserInfo(userCredential));
  },

  onAuthStateChanged: async (nextOrObserver, error) => {
    const firebaseAuth = await Firebase.importAuth();
    const auth = await firebaseAuth.getAuth();
    return firebaseAuth.onAuthStateChanged(auth, nextOrObserver, error);
  },

  vippsReauthenticate: async () => {
    const firebaseAuth = await Firebase.importAuth();
    const user = await Firebase.user();
    if (Capacitor.getPlatform() === 'ios')
      return FirebaseAuthentication.signInWithOpenIdConnect({
        providerId: 'oidc.vipps',
        scopes: VIPPS_SCOPES.split(' ')
      });

    return firebaseAuth.reauthenticateWithPopup(user, vippsOpenIDConnectProvider);
  },

  vippsLogin: () => {
    if (Capacitor.isNativePlatform())
      return FirebaseAuthentication.signInWithOpenIdConnect({
        providerId: 'oidc.vipps',
        scopes: VIPPS_SCOPES.split(' ')
      });

    return Firebase.importAuth().then(firebaseAuth =>
      firebaseAuth.signInWithPopup(firebaseAuth.getAuth(), vippsOpenIDConnectProvider).then(result => {
        const { accessToken } = firebaseAuth.OAuthProvider.credentialFromResult(result);
        NBXUsers.vippsUserInfo(accessToken)
          .then(async userInfo => {
            // eslint-disable-next-line no-console
            console.info('vippsLogin', 'userInfo', JSON.stringify(userInfo));
            await NBXStorage.setItem('vippsUserInfo', JSON.stringify(userInfo));
            // eslint-disable-next-line no-console
            console.info('vippsLogin', 'stored user info');
          })
          .catch(error => {
            console.error('vippsLogin', error);
          });

        return result;
      })
    );
  },

  signInWithCredential: async (firebaseCredential, reauthenticate) => {
    const firebaseAuth = await Firebase.importAuth();
    if (Capacitor.getPlatform() === 'ios') {
      console.log('firebase.service NbxCapacitorFirebaseAuth.signInWithCredential, ios native user');
      return reauthenticate
        ? NbxCapacitorFirebaseAuth.reauthenticateWithCredential(firebaseCredential)
        : NbxCapacitorFirebaseAuth.signInWithCredential(firebaseCredential);
    } else {
      console.log('firebase.service Firebase.signInWithCredential, web user');
      try {
        const userCredential = await (reauthenticate
          ? firebaseAuth.reauthenticateWithCredential(await Firebase.user(), firebaseCredential)
          : firebaseAuth.signInWithCredential(await firebaseAuth.getAuth(), firebaseCredential));
        console.log('firebase.service.signInWithCredential userCredential=', !!userCredential);
        return userCredential;
      } catch (signInErrorOrMfaRequired) {
        console.error('firebase.service.signInWithCredential error:', signInErrorOrMfaRequired);
        signInErrorOrMfaRequired.credential = firebaseCredential;
        throw signInErrorOrMfaRequired;
      }
    }
  },

  login: async (email, password) => {
    const firebaseAuth = await Firebase.importAuth();
    console.log('firebase.service.login, firebaseAuth.signInWithEmailAndPassword');
    const firebaseCredential = firebaseAuth.EmailAuthProvider.credential(email, password);
    return Firebase.signInWithCredential(firebaseCredential, false);
    // return firebaseAuth.signInWithEmailAndPassword(auth, email, password);
  },

  reauthenticateWithPassword: async (email, password) => {
    const firebaseAuth = await Firebase.importAuth();
    const firebaseCredential = firebaseAuth.EmailAuthProvider.credential(email, password);
    return Firebase.signInWithCredential(firebaseCredential, true);
  },

  signInWithGoogle: async ({ reauthenticate } = { reauthenticate: false }) => {
    console.log('firebase.service.signInWithGoogle reauthenticate=', reauthenticate);
    const firebaseAuth = await Firebase.importAuth();
    if (Capacitor.isNativePlatform()) {
      console.log('google OAuth: skipping native firebase sign-in to prevent native firebase mfa trigger.');
      const oAuthResult = await FirebaseAuthentication.signInWithGoogle({
        skipNativeAuth: true
      });
      console.log('firebase.service.signInWithGoogle oAuthResult=', !!oAuthResult);
      const firebaseCredential = firebaseAuth.GoogleAuthProvider.credential(oAuthResult.credential?.idToken);
      return Firebase.signInWithCredential(firebaseCredential, reauthenticate);
    } else {
      const auth = await firebaseAuth.getAuth();
      const user = await Firebase.user();
      return reauthenticate
        ? firebaseAuth.reauthenticateWithPopup(user, googleOpenIDConnectProvider)
        : firebaseAuth.signInWithPopup(auth, googleOpenIDConnectProvider);
    }
  },

  signInWithApple: async ({ reauthenticate } = { reauthenticate: false }) => {
    const firebaseAuth = await Firebase.importAuth();
    if (Capacitor.isNativePlatform()) {
      let oAuthResult;
      try {
        console.log('apple OAuth: skipping native firebase sign-in to prevent native firebase mfa trigger.');
        // this method works differently on ios and android, on android it doesn't skip firebase mfa and triggers second-factor-required
        // we cannot obtain oAuthResult from apple without handling mfa resolve but this plugin doesn't support mfa resolve
        // so, we have bug for login on android using apple for users with enables sms mfa
        oAuthResult = await FirebaseAuthentication.signInWithApple({
          skipNativeAuth: true
        });
      } catch (e) {
        console.error('cannot FirebaseAuthentication.signInWithApple', e);
        throw e;
      }
      console.log('firebase.service.signInWithApple oAuthResult=', !!oAuthResult);
      const firebaseCredential = appleOpenIDConnectProvider.credential({
        ...oAuthResult.credential,
        rawNonce: oAuthResult.credential.nonce
      });
      console.log('firebase.service.signInWithApple signInWithCredential', !!firebaseCredential);
      return Firebase.signInWithCredential(firebaseCredential, reauthenticate);
    } else {
      const auth = await firebaseAuth.getAuth();
      const user = await Firebase.user();
      return reauthenticate
        ? firebaseAuth.reauthenticateWithPopup(user, appleOpenIDConnectProvider)
        : firebaseAuth.signInWithPopup(auth, appleOpenIDConnectProvider);
    }
  },

  getRedirectResult: async () => {
    const firebaseAuth = await Firebase.importAuth();
    const auth = await firebaseAuth.getAuth();
    return firebaseAuth.getRedirectResult(auth);
  },

  isRedirectFlow: () => isMobileDevice(),

  // https://firebase.google.com/docs/reference/js/auth.md#signout
  logout: async () => {
    if (Capacitor.getPlatform() === 'ios') {
      const nativeUser = await FirebaseAuthentication.getCurrentUser();
      if (nativeUser?.user) {
        log.info('Using native signout()');
        return FirebaseAuthentication.signOut();
      }
    }

    return Firebase.importAuth().then(async firebaseAuth => {
      const auth = await firebaseAuth.getAuth();
      return firebaseAuth.signOut(auth);
    });
  },

  // https://firebase.google.com/docs/reference/js/auth.md#reload
  reloadUser: async () => {
    if (Capacitor.getPlatform() === 'ios') {
      const nativeUser = await FirebaseAuthentication.getCurrentUser();
      if (nativeUser?.user) {
        log.info('Using native reload()');
        return FirebaseAuthentication.reload();
      }
    }

    Firebase.importAuth().then(async firebaseAuth => {
      const user = await Firebase.user();
      return firebaseAuth.reload(user);
    });
  },

  reauthenticate: (email, password) => {
    // We rely on authservice to maintain sessions and so don't maintain a Firebase session
    // so to "reauthenticate" we just login again
    return Firebase.login(email, password);
  },

  // https://firebase.google.com/docs/reference/js/auth.md#sendpasswordresetemail
  resetPassword: email =>
    Firebase.importAuth().then(async firebaseAuth => {
      const auth = await firebaseAuth.getAuth();
      return firebaseAuth.sendPasswordResetEmail(auth, email);
    }),

  // https://firebase.google.com/docs/reference/js/auth.md#sendemailverification
  sendEmailVerification: settings =>
    Firebase.importAuth().then(async firebaseAuth => {
      const user = await Firebase.user();
      return firebaseAuth.sendEmailVerification(user, settings);
    }),

  // https://firebase.google.com/docs/reference/js/auth.md#checkactioncode
  checkActionCode: actionCode =>
    Firebase.importAuth().then(async firebaseAuth => {
      const auth = await firebaseAuth.getAuth();
      return firebaseAuth.checkActionCode(auth, actionCode);
    }),

  // https://firebase.google.com/docs/reference/js/auth.md#applyactioncode
  applyActionCode: actionCode =>
    Firebase.importAuth().then(async firebaseAuth => {
      const auth = await firebaseAuth.getAuth();
      return firebaseAuth.applyActionCode(auth, actionCode);
    }),

  // https://firebase.google.com/docs/reference/js/auth.md#confirmpasswordreset
  confirmPasswordReset: (actionCode, password) =>
    Firebase.importAuth().then(async firebaseAuth => {
      const auth = await firebaseAuth.getAuth();
      return firebaseAuth.confirmPasswordReset(auth, actionCode, password);
    }),

  // https://firebase.google.com/docs/reference/js/auth.md#updateemail
  changeEmail: async newEmail => {
    if (Capacitor.getPlatform() === 'ios') {
      const nativeUser = await FirebaseAuthentication.getCurrentUser();
      if (nativeUser?.user) {
        log.info('Using native updateEmail()');
        return FirebaseAuthentication.updateEmail({
          newEmail
        });
      }
    }
    Firebase.importAuth().then(async firebaseAuth => {
      const user = await Firebase.user();
      return firebaseAuth.updateEmail(user, newEmail);
    });
  },

  // https://firebase.google.com/docs/reference/js/auth.md#updatepassword
  changePassword: async password => {
    if (Capacitor.getPlatform() === 'ios') {
      const nativeUser = await FirebaseAuthentication.getCurrentUser();
      if (nativeUser?.user) {
        log.info('Using native changePassword()');
        return FirebaseAuthentication.updatePassword({
          newPassword: password
        });
      }
    }
    Firebase.importAuth().then(async firebaseAuth => {
      const user = await Firebase.user();
      return firebaseAuth.updatePassword(user, password);
    });
  },

  // https://firebase.google.com/docs/reference/js/auth.md#deleteuser
  deleteUser: async () => {
    if (Capacitor.getPlatform() === 'ios') {
      const nativeUser = await FirebaseAuthentication.getCurrentUser();
      if (nativeUser?.user) {
        log.info('Using native deleteUser()');
        return FirebaseAuthentication.deleteUser();
      }
    }
    Firebase.importAuth().then(async firebaseAuth => {
      const user = await Firebase.user();
      return firebaseAuth.deleteUser(user);
    });
  },

  createRecaptchaVerifier: ({ containerId, options }) =>
    Firebase.importAuth().then(async firebaseAuth => {
      const auth = await firebaseAuth.getAuth();
      return new firebaseAuth.RecaptchaVerifier(
        auth,
        containerId,
        window.PLATFORM === 'ios' ? { ...options, 'auth-type': 'ios' } : options
      );
    }),

  multiFactorUser: async () => {
    if (Capacitor.getPlatform() === 'ios') {
      const nativeUser = await FirebaseAuthentication.getCurrentUser();
      if (nativeUser?.user) {
        try {
          return await NbxCapacitorFirebaseAuth.getMultiFactorUser();
        } catch (e) {
          console.warn('firebase.service, cannot get NbxCapacitorFirebaseAuth.getMultiFactorUser', e.message);
          return null;
        }
      }
    }

    return Firebase.importAuth().then(async firebaseAuth => {
      const user = await Firebase.user();
      try {
        return await firebaseAuth.multiFactor(user);
      } catch (e) {
        console.warn('firebase.service, cannot get firebaseAuth.multiFactor', e.message);
        return null;
      }
    });
  },

  getMultiFactorResolver: error =>
    Firebase.importAuth().then(async firebaseAuth => {
      const auth = await firebaseAuth.getAuth();
      return firebaseAuth.getMultiFactorResolver(auth, error);
    }),

  verifyPhoneNumber: ({ phoneInfoOptions, recaptchaVerifier }) =>
    Firebase.importAuth().then(async firebaseAuth => {
      const auth = await firebaseAuth.getAuth();
      console.log(
        'firebase.service.verifyPhoneNumber, create PhoneAuthProvider, phoneInfoOptions = ',
        !!phoneInfoOptions,
        'recaptchaVerifier = ',
        !!recaptchaVerifier
      );
      const phoneAuthProvider = new firebaseAuth.PhoneAuthProvider(auth);
      console.log(
        'firebase.service.verifyPhoneNumber, preparing sending sms, renderring recaptchaVerifier, phoneAuthProvider = ',
        !!phoneAuthProvider
      );
      return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
    }),

  multiFactorAssertion: ({ verificationId, verificationCode }) =>
    Firebase.importAuth().then(async firebaseAuth => {
      const credential = firebaseAuth.PhoneAuthProvider.credential(verificationId, verificationCode);
      console.log('firebase.service.multiFactorAssertion, verify sms', !!credential);
      return firebaseAuth.PhoneMultiFactorGenerator.assertion(credential);
    }),

  enrollMultiFactorUser: async ({ verificationId, verificationCode }) => {
    if (Capacitor.getPlatform() === 'ios') {
      const nativeUser = await FirebaseAuthentication.getCurrentUser();
      if (nativeUser?.user) {
        log.info('Using native NbxCapacitorFirebaseAuth.enrollMultiFactorUser()');
        try {
          const result = await NbxCapacitorFirebaseAuth.enrollMultiFactorUser({
            verificationId,
            verificationCode
          });
          console.log('firebase.service NbxCapacitorFirebaseAuth.enrollMultiFactorUser ----', result);
          return result;
        } catch (e) {
          console.warn(
            'firebase.service, cannot get NbxCapacitorFirebaseAuth.enrollMultiFactorUser',
            e.message
          );
          return null;
        }
      }
    }

    return Firebase.importAuth().then(async firebaseAuth => {
      const user = await Firebase.user();
      console.log('firebase.service.enrollMultiFactorUser, multiFactorAssertion');
      const multiFactorAssertion = await Firebase.multiFactorAssertion({ verificationId, verificationCode });
      console.log('firebase.service.enrollMultiFactorUser, enroll user', !!multiFactorAssertion);
      return firebaseAuth.multiFactor(user).enroll(multiFactorAssertion);
    });
  },

  unenrollMultiFactorUser: async ({ factorUid }) => {
    if (Capacitor.getPlatform() === 'ios') {
      const nativeUser = await FirebaseAuthentication.getCurrentUser();
      if (nativeUser?.user) {
        log.info('Using native NbxCapacitorFirebaseAuth.unenrollMultiFactorUser()');
        return NbxCapacitorFirebaseAuth.unenrollMultiFactorUser({ factorUid });
      }
    }

    return Firebase.importAuth().then(async firebaseAuth => {
      const user = await Firebase.user();
      return firebaseAuth.multiFactor(user).unenroll(factorUid);
    });
  }
};

export default Firebase;
