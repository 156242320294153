// eslint-disable-next-line import/no-cycle
import { fetchAndFormatAssets } from '../services/assets.service';
import { fetchAndFormatMarkets } from '../services/markets.service';
import { publishAuthAccountClaimsChanged, publishAuthAccountIdChanged } from '../publish';
import { NBXStorage } from '../storage';
import { post } from '../fetch-api';
import { getAuthBaseUrl } from './url';
// eslint-disable-next-line import/no-cycle
import { getSession, publishSessionExpired, setSessionTokensRefreshTimeout } from './session';
import { AccountClaims, Session } from '../types';

export interface AccountTokenResponse {
  claims: AccountClaims;
  exp: number;
  token: string;
}

export const clearGlobalFetchAccountTokenPromise = (): void => {
  window.fetchAccountTokenPromiseId = null;
  window.fetchAccountTokenPromise = null;
};

export async function refreshAccountToken(
  session: Session,
  activeAccountId: string
  // logoutOn403 = true
): Promise<string> {
  const accountId = activeAccountId ?? session.accountId;
  if (window.fetchAccountTokenPromise && accountId === window.fetchAccountTokenPromiseId) {
    console.log(
      `[helpers.auth.refreshAccountToken] return cached fetchAccountTokenPromise (session.exp=${session?.exp})`
    );
    setTimeout(clearGlobalFetchAccountTokenPromise, 15000);
    return window.fetchAccountTokenPromise;
  }

  window.fetchAccountTokenPromiseId = accountId;
  window.fetchAccountTokenPromise = doRefreshAccountToken(session, accountId);
  return window.fetchAccountTokenPromise;
}

let accountTokenRefreshTimeout: ReturnType<typeof setTimeout>;

export const setAccountTokensDebounceRefresh = (
  response: AccountTokenResponse,
  activeAccountId: string
): void => {
  if (accountTokenRefreshTimeout) clearTimeout(accountTokenRefreshTimeout);
  accountTokenRefreshTimeout = setTimeout(
    () =>
      getSession()
        .then(session => refreshAccountToken(session, activeAccountId))
        .catch(e =>
          console.warn('setAccountTokensDebounceRefresh: cannot getSession and refreshAccountToken', e)
        ),
    (response.claims.exp - response.claims.iat) * 1000 * 0.65
  );
};

export async function doRefreshAccountToken(session: Session, accountId: string): Promise<string> {
  try {
    if (!session) {
      console.log(`[helpers.auth.refreshAccountToken] cannot refresh account token: no session`);
      throw new Error('doRefreshAccountToken: no session');
    }
    const response = (await post(`${getAuthBaseUrl()}/accounts/${accountId}/tokens`, { json: {} }, session, {
      camelizeResponseKeys: false
    })) as AccountTokenResponse;

    NBXStorage.setItem(accountId, response.token);

    setAccountTokensDebounceRefresh(response, accountId);

    try {
      const markets = await fetchAndFormatMarkets(response.claims.markets, response.token);
      const assets = await fetchAndFormatAssets(response.claims.assets, response.token);
      if (window.CAN_CACHE_IDB) {
        const time = new Date().getTime();
        window.IDB_PUBLIC_STORE.set('markets', { data: markets, time });
        window.IDB_PUBLIC_STORE.set('assets', { data: assets, time });
      }
    } catch (e) {
      console.error(
        `doRefreshAccountToken: cannot fetch markets and assets (response.token=${response?.exp} jti=${response?.claims?.jti})`,
        e
      );
      throw e;
    }

    publishAuthAccountIdChanged(accountId);
    publishAuthAccountClaimsChanged(response.claims);
    setSessionTokensRefreshTimeout(session);
    setTimeout(clearGlobalFetchAccountTokenPromise, 15000);
    return response.token;
  } catch (e) {
    console.warn(
      `doRefreshAccountToken: will logout - error while refreshing the account token using session.exp=${session?.exp}`
    );
    clearGlobalFetchAccountTokenPromise();
    if (window.PLATFORM === 'web') {
      window.LOGOUT();
    } else {
      publishSessionExpired();
    }
    throw e;
  }
}
